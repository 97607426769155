<template>
  <ClientsForm
    @save="updateClient"
    :client="client"
    :password="password"
    @validate="validate"
    ref="form"
    :type="'edit'"
    :isLoading="isLoading"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    ClientsForm: () => import('./ClientsForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    askToLeave: true,
    client: {
      email: null,
      password: null,
    },
    password: null,
    isLoading: true,
  }),
  methods: {
    validate(value) {
      this.isFormValid = value
    },
    getCurrentClient() {
      this.$api
        .get(`people/${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.client = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateClient() {
      if (this.client.client_contact.length == 0) {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar cadastrar este cliente',
          caption: 'Cadastre no mínimo um contato para este cliente',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      } 
      this.askToLeave = false
      this.$api
        .post(`people/${this.$route.params.id}`, this.client)
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Cliente atualizado com sucesso',
            confirm: () => {
              this.$router.push({ name: 'clients' })
            },
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
  created() {
    this.getCurrentClient()
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != 'budgets-edit' && this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>

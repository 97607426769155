export default {
    methods: {
        openModal(confirm, negate){
            this.$store.commit('setModal', {
            show: true,
            component: 'warning',
            text: 'Deseja realmente sair?',
            caption: 'Todas as alterações serão perdidas.',
            confirm: () => {
                this.$store.commit('setModal', {
                    show: false
                })
                confirm()
            },
            negate: () => {
                this.$store.commit('setModal', {
                    show: false
                })
                negate()
            }
            })
        }
    }
}